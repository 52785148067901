html {
  background-color: #f0f2f5;
  overflow-x: hidden;

  margin-right: calc(-1 * (100vw - 100%));
}

body {
  background-color: #f0f2f5;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

/* Move to extra.css */

/* Burger menu */

.fixed-header {
  width: 100vw;

  position: fixed;
  -webkit-box-sizing: border-box;

  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
  margin-right: calc(-1 * (100vw - 100%));
}

#page-wrap {
  /* text-align: center; */

  /* Prevent sidebar from showing a scrollbar on page */
  /* overflow: auto; */
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 4px;
  color: lightseagreen;
  transition: color 0.2s;
  font-size: 22px;
}

/* Change color on hover */
.bm-item:hover {
  color: lightblue;
  text-decoration: none;
  font-weight: 500;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  right: 5px;
  top: 8px;
  font-size: 12px;
  color: white;
  width: 33px;
  height: 33px;
  padding: 5px;
  border-radius: 50px;
  background-color: #1ca099;
}

.bm-custom-icon {
  size: 20px;
  height: 20px;
  width: 20px;
  font-size: 420px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  /* color: pink; */
  font-size: 4px;
  /* width: 20px; */
  /* height: 20px; */
  /* padding: 20px; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  color: white;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  color: white;
}

/* General sidebar styles */
.bm-menu {
  background: #257d78;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.grid-responsive {
  width: 100%;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 210px));
  /* grid-template-columns: repeat(5, 1fr 2fr); */
  margin: 2rem;
  display: grid;
  /* grid-template-columns: repeat(4, 1fr); */
  /* grid-template-rows: repeat(2, 5vw); */
  /* grid-gap: 0; */
  /* background-color: white; */
  /* border-radius: 50px; */
}

.name-tooltip {
  display: none;
}

.name-tooltip:hover {
  display: block;
}

@font-face {
  font-family: "AzoSans";
  font-style: normal;
  font-weight: 400;
  src: local("AzoSans"), url("../images/AzoSansBold.woff") format("woff");
}

@font-face {
  font-family: "AzoSansThin";
  font-style: normal;
  font-weight: 1000;
  src: local("AzoSansThin"), url("../images/AzoSans-Medium.woff") format("woff");
}

.custom-form-checkbox[type="checkbox"] {
  color: grey;
  background-color: yellow;
}

.custom-form-checkbox:after {
  color: grey;
  background-color: grey;
}

.exercise-highlight {
  background-color: grey;
}

/* .exercise-highlight:hover {
  background-color: #f2f3f7;
} */

.routine-highlight:hover {
  background-color: #f1fbeb;
}

.category-highlight:hover {
  background-color: #f2f3f7;
  border-radius: 3px;
  margin-left: 2px;
}

card-no-shadow {
  -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
  border-radius: 2;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: lightgrey;
}
input.test::placeholder {
  color: black;
  border-width: 0px;
}
.test:focus {
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 0 0;
  border-width: 1px;
  border-color: green;
}
.test:hover {
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 0 0;
  border-width: 1px;
  border-color: green;
  z-index: 999;
}
.test:active {
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 0 0;
  border-width: 1px;
  border-color: green;
  z-index: 999;
}
.test:visited {
  outline: none !important;
  border-color: transparent;
  box-shadow: 0 0 0 0;
  border-width: 1px;
  border-color: green;
  z-index: 999;
}
/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
*/ .pagination-item {
  color: grey;
  background-color: pink;
  padding: 5px;
  margin: 20px;
}

.pagination-test {
  background-color: #f9f9f9;
  color: black;
  padding: 5px;
  margin: 0px;
}

.pagination-test:hover {
  color: #4bb092;
}

.pagination-active {
  color: #4bb092;
  border: 1px solid #d9d9d9;
}

/* END MOVE */

/* LANDING CSS */
.auth-main-container {
  /* overflow: scroll; */
  font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
  /* min-height: 100vh; */
  /* display: flex;
  flex-direction: column; */
  /* background-color: #f9f9f9; */

  align-items: center;
  justify-content: center;
  /* background: linear-gradient(rgba(1, 178, 168), rgba(1, 178, 168, -0.5)),
    url("../images/bg-try.jpg"); */

  background-repeat: no-repeat;
  background-position: center;
  /* background-position-y: 50%; */
  background-size: cover;
  text-align: center;
  padding-top: 5%;
}

.login-container {
  display: flex;
  width: 200%;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.clients-container {
  padding: 20px;
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #13c999;
  border-radius: 50%;
  display: inline-block;
}

.push {
  display: flex;
  display: flex;
  height: 50%;
  flex: 0.2;
  width: 100%;
  background-color: transparent;
}

.bottom-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 10px;
  align-self: flex-end;
  background-color: transparent;
}

.nav-item {
  color: lightgray;
  font-weight: bold;
  margin: 25px;
  text-decoration: none;
}

.auth-logo {
  display: flex;
  width: 100%;
  height: 170px;
  /* margin-top: 55px; */
  flex-direction: column;
  /* background: url("../images/try.png"); */
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center;
  /* margin-top: 40px; */
  align-items: center;
  justify-content: center;
  color: rgb(24, 24, 24);
  border-width: 2px;
  font-size: 18px;
  /* background-color: green; */
  /* font-family: "AzoSans"; */
}

.auth-logo-login {
  display: flex;
  flex-grow: 1;
  width: 50%;
  height: 150px;
  /* background: url("../images/try.png"); */
  background-repeat: no-repeat;
  background-size: 95%;
  margin-right: 40px;
  background-position: center;
  margin-bottom: 60px;
  padding-top: 210px;
}

.auth-form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: 707070;
  opacity: 1; /* Firefox */
}

.auth-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.auth-form-control {
  font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif;
  display: flex;
  font-size: 16px;
  /* border: 1px 1px 1px 1px; */
  /* border-color: grey; */
  /* border-style: solid; */
  border-width: 0px;
  border-radius: 3px;
  background-color: white;

  padding-left: 5px;
  margin: 5px;
  padding: 10px;
  width: 100%;
  background: #ffffff;
   border: 1px solid rgba(128, 128, 128, 0.222);
  /* box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06); */
  border-radius: 5px;
}
hr.style-eight {
  overflow: visible; /* For IE */
  padding: 0;
  border: none;
  border-top: medium double lightgray;
  color: lightgray;
  text-align: center;
  width: 100%;

}
hr.style-eight:after {
  content: "or";
  display: inline-block;
  position: relative;
  top: -1.35em;
  font-size: 10px;
  padding: 0 0.25em;
  background: whitesmoke;
}

[type="text"] {
  color: black;
}
[type="password"] {
  color: black;
}

.registerButton {
  margin-top: 10px;
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  background: #058c42;
  width: 100%;
}

.registerButtonTwo {
  /* margin-top: 10px; */
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  color: #058c42;
  background: white;
  width: 100%;
}

.registerClientButton {
  margin-top: 10px;
  border-radius: 3px;
  padding: 10px;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  color: white;
  background: #058c42;
  width: 100%;
}

.clients-list {
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  background-color: white;
}

.client-list-item:hover {
  background-color: whitesmoke;

}

.link {
  margin-top: 5px;
  padding-top: 10px;
  font-size: 12px;

  text-decoration: none;
  color: black;
}

.list {
  padding-left: 5px;
  text-decoration: none;
  margin-top: 5px;
  list-style-type: none;
}

@media (min-width: 500px) {
  .login-container {
    width: 50%;
  }

  .clients-container {
    width: 50%;
  }

  .auth-logo {
    display: flex;
    width: 100%;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: center;
    padding-bottom: 100px;
    padding-top: 80px;
    font-size: 24px;
  }
}

@media (min-width: 1000px) {
  .login-container {
    width: 22%;
  }

  .clients-container {
    width: 25%;
  }
}

/* ROUTINE LIBRARY  */

.grid-container {
  margin-right: 10rem;
  margin-left: 10rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 20vw);
  grid-gap: 10px;
}

.grid-container-small {
  margin: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 10vw);
  grid-gap: 10px;
}

.grid_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s ease-in;
  /* border: 1px solid rgba(128, 128, 128, 0.222); */
}

.grid_exercise_image {
  width: 25%;
  height: 25%;
  object-fit: cover;
  transition: 0.5s ease-in;
  /* border: 1px solid rgba(128, 128, 128, 0.222); */
}

.grid_item {
  position: relative;
  /*   overflow: hidden will prevent the image scaling to exceed the specified width. It will expand on back */
  overflow: hidden;
  background-color: white;
  /* border-radius: 5px; */
  border: 1px solid #e8e9eb;
  min-width: 50px;
  min-height: 40px;
}

.grid_item:hover {
  /* animation: top_overlay 1s linear; */
  /* border: 2px solid white; */
  /* box-shadow: 2px 1px grey; */
  background-color: whitesmoke;
}

/* .grid_item .after {
  position: absolute;
    top: 50%;
  left:50%;
  transform : translate(-50%, -50%);
  width: 100%;
  height: 100%;
    font-size : 2rem;
    display: none;
    color: red;
} */

/* .grid_item:hover .after {
  display: block;
  background: #fff;
  object-fit : cover;
} */

.grid_item:hover .grid_image {
  /* transform : scale(1.1); */
  /* filter: blur(2px); */
}

.text {
    border: 1px solid #e8e9eb;
  font-size: 14px;
  position: absolute;
  bottom: 0%;
  left: 0%;
  transform: translate(0%, 15%);
  color: black;
  /* font-weight: 500; */
  /* display: none; */
  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
}

.sent-tag {
  font-size: 14px;
  position: absolute;
  top: 0%;
  right: 0%;
  transform: translate(0%, 0%);
  color: white;
  /* background-color: #50C6AF; */
  font-weight: 500;
  /* display: none; */
  width: 100%;
  padding: 5px;
  /* background-color: rgba(245, 245, 245, 0.694); */
}

.hover-span:hover {
  background-color: pink;
}

.text-details {
  font-size: 14px;
  position: absolute;
  top: 0%;
  left: 0%;

  /* transform: translate(-50%, -50%); */
  color: white;
  /* font-weight: 900; */
  display: none;
  background-color: #257d78;
  width: 100%;
  float: right;
  padding: 5px;
}

.online_view {
  margin-top: 40px;
  padding: 5px 10px;
  background-color: transparent;
  color: #fff;
  outline: none;
  border: 1px solid yellow;
}

button.online_view {
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

button.online_view:hover {
  background-color: #e8f6f3;
  color: #ca5d46;
}

.grid_item:hover .text-details {
  display: block;

  /*   text to see, otherwise hides in the back */
  z-index: 50;
  animation: slide 10s ease;
}

.grid_item:hover {
  cursor: pointer;
}

/*  END ROUTINE LIBRARY */

/* SWITCH TOGLE */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6b709492;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #20b2aa;
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 5px;
}

.slider.round:before {
  border-radius: 30%;
}

/* END SWITCH TOGGLE */

/* ROUND CHECKBOX */

.round {
  position: relative;
}

.round label {
  background-color: rgb(220, 220, 220);
  /* border: 1px solid #ccc; */
  border-radius: 50%;
  cursor: pointer;
  height: 18px;
  /* left: 0; */
  position: absolute;
  bottom: -8px;
  right: 2px;
  width: 18px;
}

.round label:after {
  /* border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px; */
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: lightseagreen;
  border-color: #e2f2f0;
  border-width: 4px;
  /* border-radius: 0px; */
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

/* END ROUND */

.footer {
  padding-top: 25px;
  background-color: #79aec8;
  /* border: 1px solid blue; */
  /* height: 50px; */
  text-align: center;
  color: white;
  /* padding: 5px; */
}

/* START CLIENT LIST */

.client-add-button {
  /* font: 100% / 1.414 "Open Sans", "Roboto", arial, sans-serif; */
  display: flex;
  font-size: 14px;
  /* border: 1px 1px 1px 1px; */
  /* border-color: grey; */
  /* border-style: solid; */
  border-width: 0px;
  border-radius: 3px;
  background-color: white;
  display: inline;
  padding-left: 0px;
  margin: 5px;

  margin-left: 0px;
  padding: 10px;
  width: 66%;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
}
/* END CLIENT LIST */

/* FOOTER */

/* ==============================================
   9. Footer
===============================================*/

footer {
  background-color: #292c31;
  padding: 60px 0;
  color: #62656a;
}

footer h5 {
  color: #aeb3bd;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

footer p {
  font-size: 12px;
  font-weight: 300;
  color: #62656a;
  text-align: justify;
  padding: 0px;
}

footer li {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 10px;
}

footer a {
  color: #62656a;
}

footer a:hover {
  color: #0095f7;
  text-decoration: none;
}

footer i {
  font-size: 25px;
  color: #62656a;
  margin-right: 10px;
}

footer i:hover {
  color: #0095f7;
}

footer small {
  color: #62656a;
  float: right;
}

.external-links {
  color: #0095f7;
}

/* END FOOTER */

/* MARKETING STYLES */

/* /////////////////////////////

	1. Basic Styles
	2. Navbar
	3. Buttons
	4. Hero
	5. Marketing
	6. Testimonials
	7. Pricing
	8. Call to Action
	9. Footer
	10. Media Queries

   //////////////////////////*/

body {
  font-family: "Roboto", sans-serif;
  color: #3a3f52;
}

h2 {
  font-size: 44px;
  font-weight: 700;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 25px;
}

h5 {
  font-size: 16px;
}

p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #53627c;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

b {
  font-weight: 700;
}

section {
  padding: 100px 0px;
}

.title-block {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  text-align: center;
}

.title-block p {
  font-size: 20px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
}

.divider {
  padding: 2rem 0 0;
  margin: 2rem 0 0;
  border-top: 1px solid #3c3f45;
}

.divider-light {
  padding: 2rem 0 0;
  margin: 2rem 0 0;
  border-top: 1px solid #edf1f2;
}

/* ==============================================
     2. Navbar
  ===============================================*/

.custom-navbar {
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  height: 70px;
  max-height: 70px;
}

.navbar-toggler {
  font-size: 38px;
  background-color: transparent;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: none;
}

.navbar-toggler-icon {
  color: #ffffff;
}

.navbar-collapse {
  background-color: #ffffff;
}

.nav-custom-link {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-top: 5px;
}

.navbar a {
  color: #53627c !important;
}

.nav-right {
  float: right;
}

/* ==============================================
     3. Buttons
  ===============================================*/

.btn {
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px !important;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}

.btn:hover {
  text-decoration: none;
}

.btn-regular {
  background-color: lightseagreen;
  color: #ffffff;
  padding: 10px 25px;
  letter-spacing: 0.1em;
  margin-top: 25px;
  display: inline-block;
}

.btn-regular:hover {
  background-color: #0191f0;
  color: #ffffff;
}

.btn-demo {
  color: #ffffff;
  background-color: #6772e5;
  letter-spacing: 0.1em;
  padding: 15px 48px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.btn-demo:hover {
  color: #ffffff;
  background-color: #636ee1;
}

.btn-demo-small {
  font-size: 12px;
  background-color: lightseagreen;
  padding: 1px 4px;
}

.btn-demo-small:hover {
  background-color: rgb(32, 193, 185);
}

.btn-demo-small a {
  color: #ffffff !important;
}

.btn-buy {
  color: #ffffff;
  background-color: #0095f7;
  letter-spacing: 0.1em;
  padding: 15px 48px;
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.btn-buy:hover {
  background-color: #0191f0;
  color: #ffffff;
}

/* ==============================================
     4. Hero
  ===============================================*/

#hero {
  background-color: #f6f8f9;
}

#hero h1 {
  font-size: 50px;
  font-weight: 300;
}

#hero p {
  font-size: 24px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
  padding: 0px;
}

#hero span {
  font-weight: 400;
  color: #0095f7;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* ==============================================
     5. Marketing
  ===============================================*/

#marketing p {
  font-size: 20px;
  font-weight: 300;
  color: #8da2b5;
  margin-bottom: 0px;
  padding: 0px;
}

#marketing span {
  font-weight: 400;
  color: #0095f7;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.content-box {
  padding: 60px 20px;
}

/* ==============================================
     6. Testimonials
  ===============================================*/

#testimonials {
  background-color: #f6f8f9;
  border-top: 1px solid #f8f9fb;
  border-bottom: 1px solid #f8f9fb;
}

.testimonial-box {
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 12, 32, 0.04),
    0 10px 16px 0 rgba(10, 31, 68, 0.06);
  border-radius: 20px;
  padding: 20px 30px;
  margin-top: 30px;
}

.testimonial-box h6 {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-top: 5px;
  margin-bottom: 0px;
}

.testimonial-box p {
  font-size: 16px;
  font-weight: 300;
  margin-top: 25px;
  line-height: 1.8;
  padding: 0px;
}

.testimonial-box i {
  color: #ffffff;
}

.testimonial-box small {
  color: #8da2b5;
  display: inline-block;
}

.testimonial-box span {
  font-size: 14px;
  color: #ffffff;
}

.rating {
  background-color: #0095f7;
  padding: 2px 12px;
  border-radius: 50px;
}

.profile-picture {
  display: inline-block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.review-one {
  /* background-image: url('images/profile-picture-one.jpg'); */
}

.personal-info {
  padding: 5px 0 20px 0;
  border-bottom: 1px solid #edf1f2;
}

/* ==============================================
     7. Pricing
  ===============================================*/

.pricing-box {
  background-color: #ffffff;
  padding: 40px 20px;
  border: 1px solid #f8f9fb;
  border-radius: 6px;
  box-shadow: 0 0 1px 0 rgba(0, 12, 32, 0.04),
    0 10px 16px 0 rgba(10, 31, 68, 0.06);
  margin-top: 30px;
}

.pricing-box h3 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: #0095f7;
}

.pricing-box h6 {
  font-size: 60px;
  font-weight: 700;
  color: #3a3f52;
  margin-bottom: 0px;
}

.pricing-box p {
  font-size: 17px;
  margin-top: 15px;
  padding: 0px;
}

.pricing-box ul {
  padding-left: 10px;
}

.pricing-box li {
  font-size: 14px;
  font-weight: 300;
  color: #8da2b5;
  letter-spacing: 0.1em;
}

.pricing-box span {
  font-weight: 500;
  color: #0095f7;
}

.pricing-box small {
  color: #8da2b5;
  letter-spacing: 0.2em;
}

.pricing-box i {
  font-size: 20px;
  color: #0095f7;
  margin-right: 10px;
}

.pricing-box .demo {
  color: #6772e5 !important;
}

/* ==============================================
     8. Call to Action
  ===============================================*/

#call-to-action {
  /* background-image: url('images/call-to-action_green.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

#call-to-action h2 {
  color: #ffffff;
}

#call-to-action p {
  font-size: 20px;
  font-weight: 300;
  color: #ffffff;
  opacity: 0.8;
  margin-bottom: 40px;
}

#call-to-action .title-block {
  margin-bottom: 0px;
}

#call-to-action .btn-regular {
  background-color: #ffffff !important;
  color: #0095f7 !important;
  padding: 15px 45px;
}

#call-to-action .title-block {
  margin-bottom: 0px;
}

/* ==============================================
     9. Footer
  ===============================================*/

footer {
  background-color: #292c31;
  padding: 60px 0;
  color: #62656a;
}

footer h5 {
  color: #aeb3bd;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

footer p {
  font-size: 12px;
  font-weight: 300;
  color: #62656a;
  text-align: justify;
  padding: 0px;
}

footer li {
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.5;
  margin-bottom: 10px;
}

footer a {
  color: #62656a;
}

footer a:hover {
  color: #0095f7;
  text-decoration: none;
}

footer i {
  font-size: 25px;
  color: #62656a;
  margin-right: 10px;
}

footer i:hover {
  color: #0095f7;
}

footer small {
  color: #62656a;
  float: right;
}

.external-links {
  color: #0095f7;
}

/* ==============================================
     9. Media Queries
  ===============================================*/

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  h2 {
    font-size: 34px !important;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  .title-block h2,
  p {
    text-align: left;
    padding: 0px 20px;
  }

  #call-to-action h2,
  p {
    text-align: left !important;
    padding: 0 20px;
  }

  #call-to-action {
    background-image: none;
    background-color: lightseagreen;
  }

  footer {
    padding: 60px 25px;
  }
  footer h5 {
    font-size: 18px;
    margin-top: 30px;
  }
  footer li {
    font-size: 16px;
    margin-bottom: 15px;
  }
  footer p {
    font-size: 16px;
  }
  footer i {
    font-size: 30px;
    margin-right: 20px;
  }
  footer small {
    float: left;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  #hero h1 {
    font-size: 34px !important;
    margin-bottom: 20px;
  }
  #hero p {
    font-size: 20px !important;
  }
}

@media (max-width: 991px) {
  .nav-right {
    float: left;
  }
  .nav-custom-link {
    border-bottom: 1px solid #eee;
  }
  .navbar-nav {
    width: 100%;
  }
  .navbar-nav .nav-link {
    padding: 20px 0;
    text-align: left;
  }

  .btn-demo-small {
    background-color: #ffffff;
    border: 0px;
    padding: 0px;
    box-shadow: none;
  }
  .btn-demo-small:hover {
    background-color: #ffffff;
    border: 0px;
    padding: 0px;
    box-shadow: none;
  }
  .btn-demo-small a {
    color: #53627c !important;
  }
  .nav-custom-link {
    font-size: 13px;
  }

  .icon-mobile {
    font-size: 18px;
    float: right;
  }

  .navbar-collapse {
    border-bottom: 1px solid #efefef;
  }

  .nav-logo-mobile {
    margin: auto;
    padding-right: 50px;
  }

  .nav-logo-desktop {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .icon-mobile {
    display: none;
  }
  .nav-logo-mobile {
    display: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .hero-content {
    margin-top: 100px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .pricing-box h3 {
    font-size: 17px;
  }
  .pricing-box p {
    font-size: 14px;
  }
  .pricing-box li {
    font-size: 12px;
  }
  .btn-buy {
    font-size: 12px;
    padding: 15px 20px;
  }
  .btn-demo {
    font-size: 12px;
    padding: 15px 20px;
  }
  .profile-picture {
    width: 50px;
    height: 50px;
  }
  .testimonial-box h6 {
    font-size: 14px;
    margin-left: 10px;
  }
  .testimonial-box span {
    font-size: 12px;
  }
  .testimonial-box small {
    font-size: 12px;
    margin-top: 4px;
    margin-left: 10px;
  }
}

/* END MARKETING STYLES */

/* ==============================================
     Client Table
  ===============================================*/

.styled-table-wrap {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
}

.styled-table {
  border-collapse: collapse;
  min-width: 200px;

  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}

.styled-table th {

padding: 15px;
color: grey;
border-top: 1px solid whitesmoke;
border-bottom: 1px solid whitesmoke;

font-size: 10px;

font-weight: 500;
}
.styled-table td {
padding: 15px;
  font-size: 14px;
border-bottom: 1px solid whitesmoke;

}

.styled-table tbody tr {
  /* border-bottom: 1px solid #dddddd; */

}

.styled-table tbody tr:nth-of-type(even) {
  background-color: snow;
}

.styled-table tbody tr:last-of-type {
  /* border-bottom: 2px solid #009879; */
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* ==============================================
     Intro Video
  ===============================================*/


  /**
 * Main wrapper
 */
 .select-search {
  width: 300px;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2FCC8B;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2FCC8B;
}


.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}


/* ==============================================
     Search Bar
  ===============================================*/

.search {
  display: block;
  flex: 1.2;
  margin-left: 44px;
  /* background-color: pink; */
}

.search input {
  flex: 1;
  padding: 0px 15px;
  padding-left: 0;
  background: white;
  border: none;
  font-size: 16px;
  border-radius: 0px 2px 2px 0px;
  /* border: 1px lightgrey solid; */
  border-top: 1px lightgrey solid;
  border-bottom: 1px lightgrey solid;
  border-right: 1px lightgrey solid;
}

.searchButton {
  padding: 7.5px 10px;

  width: auto;
  border: none;
  font-size: 14px;
  background: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: grey;
  border-radius: 2px 0px 0px 2px;
  border-top: 1px lightgrey solid;
  border-bottom: 1px lightgrey solid;
  border-left: 1px lightgrey solid;
}

.no-focus-color input {
  outline: none !important;
}

.search input:focus {
}

input[disabled],
input[disabled]:hover {
  background-color: white;
}

.search-extra::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: grey;

  opacity: 1; /* Firefox */
}

.search-extra:focus::placeholder {
  color: transparent;
}

.search-extra:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #898b8e;
}

.search-extra::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #898b8e;
}

.searchTerm {
  color: grey;
  font-size: 14px;
}

.searchTerm:focus {
  color: grey;
}

.searchLoading {
  width: 10px;
  margin-left: 4px;
}

/* Routine Editor */

.container {
  display: flex;
  /* width: 100vw; */
  /* height: 100vw; */
  background-color: pink;
}

.bg-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: 0.4s;

}

.content {
  position: relative;
}
